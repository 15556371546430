export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/demo.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Magazine Features</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <div className="row">
              <h3>Highlights</h3>
              <div className="list-style">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                </div>
                <div className="row"></div>
                <div className="row padding-bottom"></div>
                <h3>Distribution</h3>
                <div className="row">
                  <table>
                    <tr><th className="town">Town</th><th className="zip">Zip Code</th><th className="home"># Homes</th></tr>
                 
                    {props.data
                      ? props.data.Distribution.map((d, i) => (
                          <tr key={`${d}-${i}`}><td>{d.town}</td><td>{d.zip}</td> <td>{d.homes}</td></tr>
                        ))
                      : "loading"}
                  </table>

          
                
              </div>
            </div>
          </div>

          


        </div>
      </div>
    </div>
  );
};
