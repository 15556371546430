import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='gallery' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Sample Ads</h2>
          <p>
           We have several Ad Sizes available to provide you the best impact for reaching new clients. Whether a 1/6th Page Coupon or the Front Cover, our team has over 70 years of combined experience and is ready to help you succeed! Are you ready to INCREASE your Sales and Profits?
          </p>
        </div>
      
      
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
    
      </div>
     
    </div>
  )
}
